
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import 'ken-burns-carousel';
import './utils';
window.Alpine = Alpine

Alpine.plugin(focus);
Alpine.plugin(collapse)

// start loading Components
import * as siteComponents from './Components';

const components = {};

for (let componentKey in siteComponents) {
  components[componentKey] = siteComponents[componentKey];
}

const run = () => {
  const componentNames = Object.keys(components);
  for (let i = 0; i < componentNames.length; i++) {
    const componentName = componentNames[i];

    // Find all instances of the component
    const elements = document.querySelectorAll(`[data-component="${componentName}"]`);
    for (let j = 0; j < elements.length; j++) {
      const element = elements[j];
      if (element.dataset.initialized === 'true') {
        continue;
      }
      element.dataset.initialized = 'true';
      // Initialise component on element
      new components[componentName](element);
    }
  }
}

setTimeout(() => run(), 0);
document.addEventListener('Neos.PageLoaded', run);
// end loading Components

if (document.documentElement.dataset.debug != undefined) {
  window.Alpine = Alpine;
}


Alpine.data('tariffsData', () => ({
  tariffs: [],
  lang: 'en',
  selectedCountry: 'DE',
  countries: [],
  isLoading: false,
  init() {
    fetch('/prices.json?lang=' + this.$el.dataset.lang)
      .then(response => response.json())
      .then(response => {
        console.log(this.$el.dataset.lang);
        console.log('tariff init' , this.tariffs);
        this.isLoading = false;
        this.tariffs = response.records;
        this.countries = Object.values(response.records)[0].prices;
        this.selectedCountry = this.$el.dataset.country;
        console.log('tariffs' , this.tariffs);
      });
  },
}));

if(typeof cookieObject != 'undefined' && cookieObject.consents['default'].includes('meta-pixel')){
  !function (f, b, e, v, n, t, s) {
    if (f.fbq)
        return;
    n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq)
        f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }
  (window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '180063291750147');
  fbq('track', 'PageView');
}

Alpine.start()


